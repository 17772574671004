export const queryEnergyAllDevicesMock = {
    data: {
        queryEnergyAllDevices: {
            energy_data: {
                all_serials: {
                    cost_all_time: {
                        end_date: '2024-05-04',
                        start_date: '2021-10-01',
                        unit: '£',
                        value: 3790.0961277178,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    cost_last_day: {
                        end_date: '2024-05-03',
                        start_date: '2024-05-02',
                        unit: '£',
                        value: 1.6100922346,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    cost_last_month: {
                        end_date: '2024-05-01',
                        start_date: '2024-04-01',
                        unit: '£',
                        value: 13.952323593,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    cost_last_week: {
                        end_date: '2024-04-29',
                        start_date: '2024-04-22',
                        unit: '£',
                        value: 2.903136904,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    cost_last_year: {
                        end_date: '2024-05-01',
                        start_date: '2023-05-01',
                        unit: '£',
                        value: 814.0660648962,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    energy_all_time: {
                        end_date: '2024-05-04',
                        start_date: '2021-10-01',
                        unit: 'kWh',
                        value: 11887.586352822,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    energy_last_day: {
                        end_date: '2024-05-03',
                        start_date: '2024-05-02',
                        unit: 'kWh',
                        value: 4.602326676,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    energy_last_month: {
                        end_date: '2024-05-01',
                        start_date: '2024-04-01',
                        unit: 'kWh',
                        value: 39.86378693,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    energy_last_week: {
                        end_date: '2024-04-29',
                        start_date: '2024-04-22',
                        unit: 'kWh',
                        value: 8.29467897,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    energy_last_year: {
                        end_date: '2024-05-01',
                        start_date: '2023-05-01',
                        unit: 'kWh',
                        value: 3384.641252696,
                        __typename: 'EnergyTimeWindowResult',
                    },
                    serial_numbers: [
                        '000083ISO2XXX',
                        '000086ISO2XXX',
                        '000093ISO2XXX',
                        '000676ISO2XXX',
                        '3-phase-emc-03',
                        'VCP20000304',
                    ],
                    __typename: 'EnergyAllDevicesTimeWindowResults',
                },
                single_serials: [
                    {
                        energy_all_time: {
                            end_date: '2024-05-03',
                            start_date: '2021-10-01',
                            unit: 'kWh',
                            value: 5015.12046997,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: '000083ISO2XXX',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                    {
                        energy_all_time: {
                            end_date: '2024-05-03',
                            start_date: '2021-10-01',
                            unit: 'kWh',
                            value: 961.638396438,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: '000086ISO2XXX',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                    {
                        energy_all_time: {
                            end_date: '2023-06-01',
                            start_date: '2021-11-01',
                            unit: 'kWh',
                            value: 4221.799970276,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: '000093ISO2XXX',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                    {
                        energy_all_time: {
                            end_date: '2024-05-03',
                            start_date: '2022-10-01',
                            unit: 'kWh',
                            value: 14.580051782,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: '000676ISO2XXX',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                    {
                        energy_all_time: {
                            end_date: '2024-04-01',
                            start_date: '2023-01-01',
                            unit: 'kWh',
                            value: 1651.33373966,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: '3-phase-emc-03',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                    {
                        energy_all_time: {
                            end_date: '2023-09-01',
                            start_date: '2021-10-01',
                            unit: 'kWh',
                            value: 23.113724696,
                            __typename: 'EnergyTimeWindowResult',
                        },
                        serial_number: 'VCP20000304',
                        __typename: 'EnergySingleDeviceTimeWindowResults',
                    },
                ],
                __typename: 'EnergyAllDevicesResults',
            },
            __typename: 'EnergyAllDevicesTopLevelResults',
        },
    },
}
